import "./about.css"
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import Phototata from "./about_pic.jpg"
//import { useEffect } from "react";
//import $ from 'jquery';

function About(){


    return(
        <div className="about">
            <div className="abouttext">
                <h1>אודות - ק.מור עבודות חשמל</h1>
            </div>

            <div className="container">
                <div className="right">
                    <div className="first">
                    <h2>קובי מור</h2>
                        <div className="item">
                            <ElectricBoltIcon className="icon"/>
                            <h4>בעל ניסיון של 32 שנים בתחום!</h4>

                        </div>
                        <div className="item">
                            <ElectricBoltIcon className="icon"/>
                            <h4>בעל רישיון חשמלאי ראשי וקבלן רשום מס' 36654.</h4>
                        </div>
                        <div className="item">
                            <ElectricBoltIcon className="icon"/>
                            <h4>היכרות מעמיקה בתחום והתנסות עם מערכות ותשתיות רבות.</h4>

                        </div>
                        <div className="item">
                            <ElectricBoltIcon className="icon"/>
                            <h4>מבצע פרוייקטים למסחר, תעשייה ומשרדים.</h4>
                        </div>

                </div>
                    <div className="text">
                    <hr />
                    <p> 
                    ק. מור עבודות חשמל ותקשורת בניהולו של קובי מור, חשמלאי ראשי מאור יהודה, בעל רישיון מספר  053772 הרשום במשרד העבודה.<br/> ק.מור בעל ניסיון רב במתן שירותי חשמל, ניסיון שנרכש במשך יותר מ- 30 שנים.<br/><br/> השירות מסופק ללקוחות פרטיים ועסקיים, בהם בתים, דירות, מסעדות, משרדים וחנויות. הצוות שם דגש על מתן שירות מקצועי, יעיל ואמין עם יחס אישי וליווי צמוד בשילוב עם עמידה בלוחות זמנים עד לשביעות רצון הלקוח. הצוות המיומן בחברה ערוך לבצע פרויקטים בכל סדר גודל. 
ק.מור מוסמך לכל עבודות חשמל, החל מהכנת תשתיות חשמל, בדיקה ואיתור תקלות, טיפול בקצרים, החלפת לוחות חשמל, התקנות תשתיות לבית חכם, התקנות עמדת טעינה לרכב חשמלי, הוספה והזזת שקעים, תכנון חשמל לבית או דירה חדשה, התקנת גופי תאורה לבית ולגינה, קדיחה חציבה ועוד.<br/>
כל עבודות החשמל שהצוות מבצע, מתבצעות תחת כל התקנים הכי מחמירים של חברת החשמל גם מבחינת ביצוע העבודה וגם מבחינת חומרי החשמל בהם משתמשים.
                    </p>
                    <h5>למה לבחור ב- ק.מור עבודות חשמל?</h5>
                    <p>
                    איך לבחור חשמלאי זו שאלה טובה אך אני מאמין שיש לי את התשובה עבורכם. 
                    <br/>
ראשית אני בתחום כבר מעל ל-30 שנים, במהלך שנים אלו התנסתי רבות בתקלות ובבעיות במערכת החשמל השונות והוסמכתי כחשמלאי ראשי, כלומר אני רשאי לבצע את עבודות החשמל שישנן עד לגודל חיבור 3*250A.
בנוסף יש לי רישיון קבלן רשום כלומר אני רשאי לעבוד עם מוסדות ממשלתיים כגון: משרד הביטחון, משרדי ממשלה, בתי ספר ועוד..<br/>
 צוות ק.מור מחוייב לשמור על לקוחותינו מרוצים ולכן תמיד נפעל בהגינות ובשקיפות מלאה. הצוות דוגל בשירות מקצועי ואדיב ללקוחות פרטיים ועסקיים כאחד. <br/>אתם מוזמנים לפנות לייעוץ והכוונה בחינם בכל כעת 054-2266-843.
                    </p>
                    <h5>עבודות חשמל גדולות</h5>
                    <p>עבודות חשמל גדולות לעסקים ולפרטיים כמו תכנון חשמל לדירה עסק או בית חדש, הכנת תשתיות חשמל ועוד יבוצעו באופן מסודר ואחראי עם חוזה שבו יתועדו כל ההסכמות של הצדדים כדי שלכם לא היו שום דאגות ואנו נוכל לבצע את העבודה על הצד הטוב ביותר עבורכם.</p>
                    <h5>חשמלאי במרכז לכל עבודות החשמל והתקשורת</h5>
                    <p>
                    רבים מאיתנו תוהים האם בכל תקלה חובה להזמין חשמלאי או שאפשר לנסות ולתקן בעצמנו את התקלה, הובכן הכן חובה! לא תמיד התקלות הן גדולות, לעיתים מדובר בשקע חשוף, חוט קטן שהשתחרר או כל דבר שנראה לנו פשוט וקל לתקן,

כשמדובר בחשמל הסכנה היא גדולה, אדם בלתי מיומן ומוסמך אינו יכול להבחין בסקנות העורבות מהתעסקות בחשמל.  <br/> ישנם מקרים רבים שנגמרו באסון שיכול היה להמנע לולא היה טיפול של חשמלאי,

לכן חל איסור מוחלט לאדם בלתי מוסמך לעבוד עם חשמל בכל מקרה בו הוא ניתקל, כאשר אתם רואים חוט או שקע חשוף הזמינו מייד חשמלאי בראש שקט אשר ידע כיצד לטפל במקרה בבטחה.
                    </p>
                    </div>
                </div>
                <div className="left">
                    <img src={Phototata} alt='Kobi Mor'></img>
                </div>
            </div>
        </div>
    )
}

export default About;