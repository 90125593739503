import "./accessibility.css"

function accessibility(){
    return(
        <div className="accessibility">
         <h5>הנגישות באתר מותאמת לדפדפנים המובילים:</h5>
         <ul>
            <li>פיירפוקס</li>
            <li>כרום</li>
            <li>אקספלורר 9 ומעלה</li>
            <li>אופרה</li>
         </ul>
         <h5>מדריך למשתמש בתפריט:</h5>
         <ul>
            <li>כפתור התאמת האתר ותגיות האתר עבור מכשירי עזר וטכנולוגיות עבור בעלי מוגבלויות
</li>
            <li>כפתור איפשור ניווט בעזרת מקשי המקלדת בין הקישורים שבאתר
</li>
            <li>כפתור השבתת הבהובים ו/או אלמנטים נעים על המסך
</li>
            <li>כפתור איפשור מצב מונוכרום שחור לבן עבור עיוורי צבעים
</li>
            <li>כפתור ספיה (גוון חום)
</li>
            <li>כפתור שינוי ניגודיות גבוהה
</li>
            <li>כפתור שחור צהוב
</li>
            <li>כפתור היפוך צבעים
</li>
            <li>כפתור המדגיש בצורה ברורה את כל תגי הכותרות המופיעים באתר
</li>
            <li>כפתור המדגיש בצורה ברורה את כל הקישורים המופיעים באתר
</li>
            <li>כפתור המציג את התיאור החלופי של כל התמונות המופיעות באתר במעבר עכבר
</li>
            <li>כפתור המציג תיאור קבוע של התמונות באתר
</li>
<li>כפתור ביטול שימוש בגופן קריא
</li>
<li>כפתור הגדלת גודל הגופנים באתר
</li>
<li>כפתור הקטנת גודל הגופנים באתר
</li>
<li>כפתור הגדלת כל  התצוגה לכ־200%
</li>
<li>כפתור הקטנת כל  התצוגה לכ־70%
</li>
<li>כפתור הגדלת סמן העכבר
</li>
<li>כפתור הגדלת סמן העכבר ושינוי צבעו לשחור
</li>
<li>כפתור מצב קריאת האתר
</li>
<li>כפתור המציג את הצהרת הנגישות
</li>
<li>כפתור איפוס המבטל את הנגישות
</li>
<li>כפתור שליחת משוב נגישות
</li>
<li>כפתור שינוי שפת הסרגל והצהרת הנגישות בהתאם
</li>
</ul>
<h5>בסרגל הנגישות יש 2 סוגים של הגדלות לנוחיותכם, אך אם תרצו להגדיל עוד את האותיות תוכלו להשתמש בפונקציות המקלדת הבאות:
</h5>
<ul>
    <li>מקש Esc יפתח ויסגור את סרגל הנגישות
</li>
    <li>מקש Ctrl + יגדיל את הטקסט באתר
</li>
    <li>מקש Ctrl – יקטין את הטקסט באתר
</li>
    <li>מקש Ctrl 0 יחזיר את האתר לגדלו המקורי
</li>
    <li>מקש רווח (SPACE) יוריד את האתר כלפי מטה.
</li>
<li>מקש F11 יגדיל את המסך לגודל מלא – לחיצה נוספת תקטין אותו חזרה.
</li>

</ul>
<h5>למען הסר ספק:
</h5>
<p>
אנחנו מחויבים להפוך את אתרינו לנגיש לכלל האנשים,בעלי יכולות ובעלי מוגבלויות.
באתר זה תוכלו למצוא את הטכנולוגיה המתאימה לצרכים שלכם.
אתר זה הנו אתר שמיש לכלל האוכלוסייה ברובו ובהשתדלות מקסימאלית..

ייתכן ותמצאו אלמנטים שאינם מונגשים כי טרם הונגשו או שלא נמצאה טכנולוגיה מתאימה ולצד זה אנו מבטיחים כי מתבצעים מרב המאמצים לשפר ולהנגיש ברמה גבוהה ובלי פשרות.

במידה ונתקלתם בקושי בגלישה באתר וצפייה בתוכנו אנו מתנצלים ונשמח מאוד כי תפנו את תשומת ליבנו לכך .

</p>
<h5>נתקלת בבעיה באתר?
</h5>
<p>רשמו לנו על התקלות בהם נתקלתם עם פירוט מורחב ככל שניתן על מנת שנוכל לטפל בבעיה במהירות האפשרית.
</p>
<p>בפניה על תקלה נא לציין:
</p>
<ul>
    <li>תיאור הבעיה
</li>
    <li>באיזה דף גלשתם?
</li>
    <li>סוג הדפדפן
</li>
    <li>סוג הטכנולוגיה המסייעת (במידה והשתמשתם)
</li>
</ul>
<p>אנו נטפל בבעיה בהקדם עם פרטים על טיפולה!
</p>
<h5>הסדרי נגישות פיזיים:
</h5>
<p>גישה- אין גישה לבעלי מוגבלות או אדם אחר, אני מגיע אליכם בלבד.
</p>
<p>אנו נוקטים באמצעים ומשאבים רבים בכדי לספק לכלל לקוחותיה שירות שוויוני, מקצועי ונגיש.
</p>
<p>עם זאת חשוב לציין כי ייתכן וימצאו אלמנטים מסוימים שאינם מונגשים בצורה מלאה או נמצאים בתהליכי הנגשה לאנשים עם מוגבלות.
</p>
<h5>נתקלתם ברכיב שאינו נגיש באתר?
</h5>
<p>פנו אלינו ואנו מבטיחים לבדוק לשפר אותו בהקדם האפשרי וכמובן להעניק לכם את השירות הטוב ביותר בצורה מהירה, אישית ובהתאם לשביעות רצונכם.
</p>
<p>לבירורים נוספים:
</p>
<p>בכל שאלה בנושא נגישות ניתן לפנות לאחראי הנגישות:
</p>
<p>שם: עומר מור
</p>
<p>בטלפון: 0544440136
</p>
<p>דואר אלקטרוני: omer6688@gmail.com</p>
<br></br>
<p>הצהרת הנגישות עודכנה בתאריך 01.01.24</p>


        </div>
    )
}

export default accessibility;