import "./gallery.css"
import p1 from "./p1gallery.jpg"
import p2 from "./p2gallery.jpg"
import p3 from "./p3gallery.jpg"
import p4 from "./p4gallery.jpg"
import p5 from "./p5gallery.jpg"
import p6 from "./p6gallery.jpg"
import p7 from "./p7gallery.jpg"
import p8 from "./p8gallery.jpg"
import p9 from "./p9gallery.jpg"
import p10 from "./p10gallery.jpg"


function gallery(){
    return(
        <div className="gallery">
        <div className="top">
        <h1>גלריית תמונות</h1>

        </div>
    <div class="gallery-container">
        <img src={p9} alt="photo of kobi"/>
        <img src={p10} alt="photo of lamp"/>
        <img src={p2} alt="photo of lamp"/>
        <img src={p1} alt="photo of kobi"/>
        <img src={p3} alt="photo of lamp"/>
        <img src={p4} alt="photo of electrical cabinet"/>
        <img src={p5} alt="photo of lamp"/>
        <img src={p6} alt="photo of lamp"/>
        <img src={p7} alt="photo of electrical cabinet"/>
        <img src={p8} alt="photo of lamp"/>
    </div>
        </div>
    )
}
export default gallery;