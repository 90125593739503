import "./services.css"

function services(){
    return(
        <div className="services">
            <div className="top">
                <h1>שירותים</h1>
            </div>
            <div className="bottom">
                               <h5>שירותי תיקון נפוצים</h5>
                <ol class="gradient-list">
    <li>בדיקת תקינות מערכות חשמל ומתן אישורים למכבי אש ולחברות הביטוח</li>
    <li>שיקום נזקי חשמל לאחר הצפה או שריפה</li>
    <li>עבודות חשמל ותקשורת במתח נמוך</li>
    <li>התקנת מערכות "בית חכם" לשליטה ממוחשבת במערכות חשמל</li>
    <li>תיקון דודים ומאיצי חום</li>
    <li>מתן פתרונות לבעיות עומס</li>
    <li>הכנה לביקורות חברת החשמל</li>
    <li>הוספת נקודות חשמל ותקשורת</li>
    <li>התקנת נקודות כוח למזגנים</li>
    <li>עיצוב ותכנון של תאורת גינה</li>
    <li>התקנת תאורת חירום</li>
    <li>התקנת גופי תאורה</li>
    <li>עמדות טעינה לרכבים חשמליים</li>
    <li>פרויקטים משרדים ותעשיה משלב התכנון ועד למסירה</li>
    <li>יעוץ והכוונה מלאה ומקצועית</li>
  </ol>

            </div>
        </div>
    )
}

export default services;